.banner_section {
    width: 100%;
    float: left;
    background-color: #567DA7;
    background-image: url(../img/anigif.gif);
    height:50rem;
    padding-bottom: 90px;
    background-size: 100% 100%;
    background-position: center top;
    background-repeat: no-repeat;
    opacity: 10;
}

.best_taital{
    width: 100%;
    float: left;
    font-size: 35x;
    color: #ffffff;
    text-align: center;
    z-index: 10;
    background-color:rgba(0, 0, 0, 0.093);
    opacity:1;
}

#available{
     width: 100%;
     text-align: center;
     z-index: 10;
     margin-top:20px;
     text-transform: capitalize;
     box-shadow: 0 3px 5px rgba(197, 157, 40, 0.2);
 }

 #camry{
height:350px
 }

 #occation{
margin-top: 50px;

 }

 #party{
height: 197px
 }

 @media print{
#myprint{
     display: none;
}
 }

 #servicesrow{
     color:green
 }
 

 #bookingEdit{
     margin-top: 50px;
     }

 #atag{
font-size: 15px;
 }

 #dbt{
width: 200px;
 }

 @media screen and (max-width: 768px) {
   iframe{
 width:100%;

   }
   }

   @media screen and (max-width: 740px) {
     iframe{
   width:100%;
  
     }
     }

#btnbook,btnchart{
border:1px solid red;
border-radius: 10px;
margin-right: 10px
}

hover{
     #btnbook{
          border:1px solid red;
          border-radius: 10px;
          margin-right: 10px;
          background-color: blue;
          }
}

@import url('https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');

/*****---------------------------------------- 1) font-family: 'Rajdhani', sans-serif;
 2) font-family: 'Poppins', sans-serif;
 ----------------------------------------*****/


/*--------------------------------------------------------------------- import Files ---------------------------------------------------------------------*/


html {
     scroll-behavior: smooth;
}

body {
     color: #666666;
     font-size: 14px;
     font-family: Poppins;
     line-height: 1.80857;
     font-weight: normal;
     overflow-x: hidden;
}

a {
     color: #1f1f1f;
     text-decoration: none !important;
     outline: none !important;
     -webkit-transition: all .3s ease-in-out;
     -moz-transition: all .3s ease-in-out;
     -ms-transition: all .3s ease-in-out;
     -o-transition: all .3s ease-in-out;
     transition: all .3s ease-in-out;
}






*,
*::after,
*::before {
     -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
     box-sizing: border-box;
}


button:focus {
     outline: none;
}

ul,
li,
ol {
     margin: 0px;
     padding: 0px;
     list-style: none;
}

p {
     margin: 20px;
     font-weight: 300;
     font-size: 15px;
     line-height: 24px;
}

a {
     color: #222222;
     text-decoration: none;
     outline: none !important;
}

a,
.btn {
     text-decoration: none !important;
     outline: none !important;
     -webkit-transition: all .3s ease-in-out;
     -moz-transition: all .3s ease-in-out;
     -ms-transition: all .3s ease-in-out;
     -o-transition: all .3s ease-in-out;
     transition: all .3s ease-in-out;
}

img {
     max-width: 100%;
     height: auto;
}

 :focus {
     outline: 0;
}

.paddind_bottom_0 {
     padding-bottom: 0 !important;
}

.btn-custom {
     margin-top: 20px;
     background-color: transparent !important;
     border: 2px solid #ddd;
     padding: 12px 40px;
     font-size: 16px;
}

.lead {
     font-size: 18px;
     line-height: 30px;
     color: #767676;
     margin: 0;
     padding: 0;
}

.form-control:focus {
     border-color: #ffffff !important;
     box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .25);
}

.navbar-form input {
     border: none !important;
}

.badge {
     font-weight: 500;
}

blockquote {
     margin: 20px 0 20px;
     padding: 30px;
}

button {
     border: 0;
     margin: 0;
     padding: 0;
     cursor: pointer;
}

.full {
     float: left;
     width: 100%;
}

.layout_padding {
     padding-top: 90px;
     padding-bottom: 0px;
}

.padding_0{
     padding: 0px;
}

.navbar-light .navbar-nav .nav-link {
    color: #676868;
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 15px;
    padding-left: 15px;
    font-size: 16px;
}

.navbar-light .navbar-nav .nav-link:hover {
    color: #1898b8;
}

.logo {
    width: 40%;
    float: left;
}

.login_text{
     width: 8%;
     float: left;
     font-size: 16px;
     color: #1898b8;
}

.login_text a{
     color: #1898b8;
}

.login_text a:hover{
     color: #676868;
}





.email_bt {
    width: 70%;
    float: left;
    font-size: 16px;
    color: #c3c2c2;
    background-color: #ffffff;
    text-align: left;
    padding-left: 15px;
    height: 50px;
    border: 0px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.subscribe_bt {
    width: 30%;
    background-color: #313233;
    float: right;
    padding: 9px 14px;
    color: #fff;
    height: 50px;
    font-size: 16px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.subscribe_bt a {
    color: #ffffff;
}

.box_main {
    width: 50%;
    margin: 0 auto;
    text-align: center;
    padding-top: 30px !important;
    display: flex;
}

.there_text {
    width: 70%;
    margin: 0 auto;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    font-family: poppin;
    color: #ffffff;
    padding-top: 50px;
    display: flex;
    background-color:rgba(0, 0, 0, 0.093);
}

.bt_main{
     width: 20%;
     margin: 0 auto;
     text-align: center;
}

.discover_bt {
    width: 100%;
    float: left;
    margin-top: 50px;
}

.discover_bt a{
     width: 100%;
     float: left;
     font-size: 18px;
     color: #ffffff;
     text-align: center;
     padding: 10px 0px;
     background-color: rgba(0, 0, 0, 0.758);
     border-radius: 8px;
}
.discover_bt a:hover{
     color: #252525;
     background-color: #ffffff;
}

.marketing_section{
     width: 100%;
     float: left;
}

.job_section{
     width: 70%;
     float: right;
}

.jobs_text{
     width: 100%;
     float: left;
     font-size: 46px;
     color: #252525;

}

.dummy_text{
     width: 100%;
     float: left;
     font-size: 16px;
     color: #252525;
     margin-left: 0px;
}

.apply_bt {
    width: 45%;
    float: left;
    margin-top: 50px;
}

.apply_bt a{
     width: 100%;
     float: left;
     font-size: 18px;
     color: #ffffff;
     text-align: center;
     padding: 10px 0px;
     background-color: #252525;
}
.apply_bt a:hover {
    color: #fff;
    background-color: #0c8aba;
}

.image_1{
     width: 100%;
     float: left;
}

.job_section_2{
     width: 70%;
     float: left;
}

.footer_section {
    width: 100%;
    float: left;
    background-color: #00aeef;
    height: auto;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-top: 60px;
}

.subscribr_text{
     width: 100%;
     float: left;
     font-size: 30px;
     color: #ffffff;
}

.lorem_text{
     width: 100%;
     float: left;
     font-size: 16px;
     color: #ffffff;
     margin-left: 0px;
}


.email_bt_2 {
    width: 100%;
    float: left;
    font-size: 16px;
    color: #c3c2c2;
    background-color: #ffffff;
    text-align: left;
    padding-left: 15px;
    height: 60px;
    border: 0px;
    border-radius: 20px;
    padding-right: 250px;
    padding-top: 16px;
}

.subscribe_bt_2 {
    width: 20%;
    background-color: #313233;
    float: right;
    padding: 9px 14px;
    color: #fff;
    height: 50px;
    font-size: 16px;
    border-radius: 20px;
    top: -55px;
    position: relative;
    right: 5px;
}

.subscribe_bt_2 a {
    color: #ffffff;
}

.box_main_2 {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding-top: 30px !important;
    display: flex;
}

.copyright_section{
     width: 100%;
     float: left;
     background-color: #ffffff;
     height: auto;
}

.copyright_text {
    width: 100%;
    float: left;
    font-size: 16px;
    color: #111111;
    margin-left: 0px;
}

.cookies_text{
    width: 100%;
    float: left;
    font-size: 16px;
    color: #111111;
    margin-left: 0px;
    text-align: right;
}



.logo_1{
     width: 100%;
     float: left;
     text-align: center;
}

.what_text {
    width: 100%;
    float: left;
    font-size: 24px;
    color: #ffffff;
    text-align: left;
    margin: 0;
}

.city_text {
    width: 100%;
    float: left;
    font-size: 14px;
    color: #ffffff;
    margin-left: 0px;
    margin-top: 0px;
    text-align: left;
    padding-bottom: 20px;
}

.ahmedabad_text {
    width: 100%;
    float: left;
    font-size: 14px;
    color: #000;
    padding-left: 15px;
    padding-right: 20px;
    height: 50px;
    border: 2px solid #8d8e8e;
    border-radius: 5px;
}

/* Styles for wrapping the search box */

.main {
    width: 100%;
    margin: 50px auto;
}

/* Bootstrap 4 text input with search icon */

.has-search .form-control {
    padding-left: 2.375rem;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}

.input-group>.form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: 50px;
}

.btn { 
     padding: 10px 20px;
}

.box{
     width: 100%;
     float: left;
     margin-top: 90px;
}

.find_bt {
    width: 15%;
    margin: 0 auto;
    text-align: center;
    display: block;
    height: 50px;
    border-radius: 5px;
    background-color: #fff;
    color: #000;
    font-size: 18px;
}

.companies_section{
     width: 100%;
     float: left;

}

.companies_text {
    width: 41%;
    margin: 0 auto;
    font-size: 46px;
    color: #000;
    text-align: center;
    border-bottom: 5px solid #46c3f3;
}



h2 {
     margin: 60px 15px 0;
     padding: 0;
     font-weight: 300;
}
h2 span {
     margin-left: 1em;
     color: #aaa;
     font-size: 85%;
}
.column {
     margin: 15px 15px 0;
     padding: 0;
}
.column:last-child {
     padding-bottom: 60px;
}
.column::after {
     content: '';
     clear: both;
     display: block;
}
.column div {
     position: relative;
     float: left;
     width: 250px;
     height: 200px;
     margin: 0 0 0 25px;
     padding: 0;
}
.column div:first-child {
     margin-left: 0;
}
.column div span {
     position: absolute;
     bottom: -20px;
     left: 0;
     z-index: -1;
     display: block;
     width: 250px;
     margin: 0;
     padding: 0;
     color: #444;
     font-size: 20px;
     text-decoration: none;
     text-align: center;
     -webkit-transition: .3s ease-in-out;
     transition: .3s ease-in-out;
     opacity: 0;
}
figure {
     width: 250px;
     height: 200px;
     margin: 0;
     padding: 0;
     background: #fff;
     overflow: hidden;
}
figure:hover+span {
     bottom: -36px;
     opacity: 1;
}



/* Zoom In #1 */
.hover01 figure img {
     -webkit-transform: scale(1);
     transform: scale(1);
     -webkit-transition: .3s ease-in-out;
     transition: .3s ease-in-out;
}
.hover01 figure:hover img {
     -webkit-transform: scale(1.3);
     transform: scale(1.3);
}

/* Zoom In #2 */
.hover02 figure img {
     width: 250px;
     height: auto;
     -webkit-transition: .3s ease-in-out;
     transition: .3s ease-in-out;
}
.hover02 figure:hover img {
     width: 350px;
}

/* Zoom Out #1 */
.hover03 figure img {
     -webkit-transform: scale(1.5);
     transform: scale(1.5);
     -webkit-transition: .3s ease-in-out;
     transition: .3s ease-in-out;
}
.hover03 figure:hover img {
     -webkit-transform: scale(1);
     transform: scale(1);
}

.job_main{
     text-align: center;
}



.services_text{
     width: 100%;
     float: left;
     font-size: 50px;
     color: #ffffff;
     text-align: center;
}

.services_section_2{
     width: 100%;
     float: left;
}

.services_item {
    width: 100%;
    float: left;
    margin-top: 80px;
}

.services_1{
     width: 100%;
     float: left;
     text-align: center;
}

.jobs_main {
    width: 100%;
    float: left;
    font-size: 26px;
    text-align: center;
    color: #000;
    padding-top: 20px;
}

.many_main {
    width: 88%;
    float: left;
    font-size: 12px;
    text-align: center;
    color: #000;
    margin-top: 0;
}

.join_bt {
    width: 55%;
    margin: 0 auto;
    text-align: center;
}

.join_text{
     width: 100%;
     float: left;
}

.join_text a{
     width: 100%;
     float: left;
     background-color: #000;
     font-size: 18px;
     text-align: center;
     padding: 10px;
     color: #ffffff;
}

.join_text a:hover {
    color: #fff;
    background-color: #0c8aba;
}

.login_section {
    width: 100%;
    float: left;
    margin-top: 60px;
}


:root {
  --input-padding-x: 1.5rem;
  --input-padding-y: .75rem;
}

.login_section {
  background: #007bff;
  background: linear-gradient(to right, #0062E6, #33AEFF);
}

.card-signin {
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.card-signin .card-title {
  margin-bottom: 2rem;
  font-weight: 300;
  font-size: 1.5rem;
}

.card-signin .card-body {
  padding: 2rem;
}

.form-signin {
  width: 100%;
}

.form-signin .btn {
  font-size: 80%;
  border-radius: 5rem;
  letter-spacing: .1rem;
  font-weight: bold;
  padding: 1rem;
  transition: all 0.2s;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group input {
  height: auto;
  border-radius: 2rem;
}

.form-label-group>input,
.form-label-group>label {
  padding: var(--input-padding-y) var(--input-padding-x);
}

.form-label-group>label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  border: 1px solid transparent;
  border-radius: .25rem;
  transition: all .1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:placeholder-shown)~label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #777;
}

.btn-google {
  color: white;
  background-color: #ea4335;
}

.btn-facebook {
  color: white;
  background-color: #3b5998;
}

/* Fallback for Edge
-------------------------------------------------- */

@supports (-ms-ime-align: auto) {
  .form-label-group>label {
    display: none;
  }
  .form-label-group input::-ms-input-placeholder {
    color: #777;
  }
}

/* Fallback for IE
-------------------------------------------------- */

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .form-label-group>label {
    display: none;
  }
  .form-label-group input:-ms-input-placeholder {
    color: #777;
  }
}

.Recurments_section {
    width: 100%;
    float: left;
    background-color: #d6f2ee;
    height: auto;
    padding: 40px 0px;
    margin-top: 90px;
}

.btn_main {
    width: 100%;
    float: left;
}

.btn_main_2 {
    float: left;
    padding-right: 0;
    background-color: #36bbe9;
    color: #fff;
    text-align: center;
    margin-right: 12px;
    height: 53px;
    padding: 15px 0px;
    font-size: 18px;
    border-radius: 40px;
    border: 0px;
}

input.btn_main_2::placeholder {
    color: #ffffff;
}

.btn_main_3 {
    float: left;
    padding-right: 0;
    background-color: #222121;
    color: #fff;
    text-align: center;
    margin-right: 12px;
    height: 53px;
    padding: 15px 35px;
    font-size: 18px;
    border-radius: 40px;
    border: 0px;
}

input.btn_main_3::placeholder {
    color: #ffffff;
}

.Recurments_section_2{
    width: 100%;
    float: left;
}

.product_section {
    width: 100%;
    float: left;
    border-bottom: 4px solid #c3c3c2;
    padding-top: 50px;
}

.one_text{
    width: 100%;
    float: left;
}

.one_text a.active{
    background-color: #222121 !important;
}

.one_text a{
    width: 45%;
    float: left;
    text-align: center;
    border-radius: 50px;
    color: #ffffff;
    background-color: #36bbe9;
    height: 68px;
    font-size: 30px;
    padding-top: 8px;
    margin-top: 20px;
}

.one_text a:hover{
    background-color: #222121;
    color: #ffffff;
}

.software_text{
    width: 100%;
    float: left;
    font-size: 40px;
    color: #36bbe9
}

.lorem_ipsum_text{
    width: 100%;
    float: left;
    font-size: 16px;
    color: #222121;
    margin-left: 0px;
    margin-top: 0px;
}

.padding_top_0{
    padding-top: 30px;
}

.apply_now {
    width: 100%;
    float: left;
    font-size: 18px;
    color: #ffffff;
    background-color: #36bbe9;
    height: 50px;
    border-radius: 30px;
    margin-top: 20px;
}


@media (max-width: 768px) and (orientation: portrait) {
     #getus {
      margin-left: 90px;
      border: 2px solid red;
     }
   }

   
@media (max-width: 740px) and (orientation: portrait) {
     #getus {
      margin-left: 50px;
  
     }
     #getcode{
     height: 84px;


     }
   }